
  import { defineComponent, onMounted, ref, computed, watchEffect } from "vue";
  import { hideModal } from "@/core/helpers/dom";
  import Swal from "sweetalert2/dist/sweetalert2.js";
  import { Actions } from "@/store/enums/StoreEnums";
  import { useStore } from "vuex";
  import ApiService from "@/core/services/ApiService";
  
  export default defineComponent({
    name: "add-parametr-modal",
    components: {},
    props: ['edit', 'item'],
    setup(props) {
      const formRef = ref<null | HTMLFormElement>(null);
      const addLanguageModalRef = ref<null | HTMLElement>(null);
      const store = useStore();
      const loading = ref<boolean>(false);
      const add = ref<boolean>(true)
      const formData = ref({
        title: "",
        icon: "",
        text: "",
      });


      const resetForm = ()=>{
        formData.value.title = "",
        formData.value.text = ""
        formData.value.icon = ""
      }
  
      const rules = ref({
        title: [
          {
            required: true,
            message: "Customer name is required",
            trigger: "change",
          },
        ],
        icon: [
          {
            required: true,
            message: "Customer name is required",
            trigger: "change",
          },
        ],
        text: [
          {
            required: true,
            message: "Customer name is required",
            trigger: "change",
          },
        ],
      });



      watchEffect(()=>{
        add.value = !props.edit
        if(!add.value){
          ApiService.get('setting/'+ props.item).then((res)=>{
              formData.value = res.data;
            
          })
        }else{
          resetForm()
        }
      })

  
      const submit = () => {
        if (!formRef.value) {
          return;
        }
  
        formRef.value.validate((valid) => {
          if (valid) {
            loading.value = true;
            if(add.value){
              store.dispatch(Actions.ADD_PARAMETR, formData);
            }else{
              store.dispatch(Actions.EDIT_PARAMETR, formData);
            }
            
            setTimeout(() => {
              loading.value = false;
  
              Swal.fire({
                text: "Form has been successfully submitted!",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                resetForm();
                hideModal(addLanguageModalRef.value);
              });
            }, 200);
          } else {
            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
            return false;
          }
        });
      };
  
      
      return {
        formData,
        rules,
        submit,
        formRef,
        loading,
        addLanguageModalRef, add
      };
    },
  });
  